import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const ISwiper: React.FC<Swiper> = ({ children, ...props }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(1);
  }, []);

  return (
    <Swiper key={key} {...props}>
      {children}
    </Swiper>
  );
};

export { ISwiper, SwiperSlide };
export default ISwiper;
