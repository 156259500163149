import React, { useEffect, useRef } from 'react';
import Image from 'next/legacy/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper/core';
import SwiperClass from 'swiper/types/swiper-class';

import Link from 'next/link';
import Mobile from '@/components/Device/Mobile';
import PC from '@/components/Device/PC';
import useIsMobile from '@/hooks/useIsMobile';

import 'swiper/swiper.min.css';

import styles from './style.module.scss';

interface ItemProps {
  key: string;
  linkUrl: string;
  imgUrl: string;
  width: number;
  height: number;
  alt: string;
  // mobile
  mWidth: number;
  mHeight: number;
  mAlt: string;
}

const NEWS_LIST: ItemProps[] = [
  {
    key: 'yahoo',
    linkUrl: 'https://finance.yahoo.com/news/zealand-based-edtech-company-wukong-140000022.html',
    imgUrl: '/images/home/In-the-news-1.png',
    width: 155,
    height: 57,
    alt: '',
    mWidth: 53,
    mHeight: 19,
    mAlt: '悟空中文在 Zino',
  },
  {
    key: 'ap',
    linkUrl: 'https://apnews.com/article/technology-science-education-new-zealand-0bb66147901d655919341270695597bf',
    imgUrl: '/images/home/In-the-news-9.png',
    width: 86,
    height: 100,
    alt: '',
    mWidth: 28,
    mHeight: 33,
    mAlt: '',
  },
  {
    key: 'la',
    linkUrl: 'https://lapost.us/?p=47134',
    imgUrl: '/images/home/In-the-news-4.png',
    width: 205,
    height: 28,
    alt: '',
    mWidth: 68,
    mHeight: 9,
    mAlt: '悟空中文在 yahoo finance',
  },
  {
    key: 'pie',
    linkUrl:
      'https://thepiereview.mydigitalpublication.com?m=65429&i=778840&view=articleBrowser&article_id=4470079&bt_field_name[]=utm_content&bt_field_name[]=utm_medium&bt_field_name[]=utm_source&bt_field_value[]=239861337&bt_field_value[]=social&bt_field_value[]=linkedin&hss_channel=lcp-2248093&utm_content=239861337&utm_medium=social&utm_source=linkedin&ver=html5',
    imgUrl: '/images/home/In-the-news-10.png',
    width: 207,
    height: 28,
    alt: '',
    mWidth: 69,
    mHeight: 9,
    mAlt: '',
  },
  {
    key: 'cision',
    linkUrl:
      'https://www.prnewswire.com/news-releases/new-zealand-based-edtech-company-wukong-education-ceo-shared-insights-at-the-holoniq-summit-2022-301665880.html',
    imgUrl: '/images/home/In-the-news-3.png',
    width: 162,
    height: 60,
    alt: '',
    mWidth: 51,
    mHeight: 20,
    mAlt: '悟空中文在 yahoo finance',
  },
];

SwiperCore.use([Autoplay]);

const InTheNews: React.FC = () => {
  const swiperRef = useRef<SwiperClass>();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      swiperRef.current?.enable();
    } else {
      swiperRef.current?.disable();
    }
  }, [isMobile]);

  const handleSwiperInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
  };

  return (
    <section className={styles.container}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>In The News</h2>
      </div>
      <PC>
        <div className={styles.content}>
          <div className={styles.line}>
            {NEWS_LIST.map((item) => (
              <Link key={item.key} href={item.linkUrl} passHref target="_blank" className={styles.item}>
                <Image src={item.imgUrl} width={item.width} height={item.height} alt={item.alt} />
              </Link>
            ))}
          </div>
          {/* <div className={styles.line}>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-1.png" width={155} height={57} alt="" />
            </div>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-2.png" width={190} height={132} alt="" />
            </div>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-3.png" width={162} height={60} alt="" />
            </div>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-4.png" width={256} height={32} alt="" />
            </div>
          </div> */}
          {/* <div className={styles.line}>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-5.png" width={120} height={101} alt="" />
            </div>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-6.png" width={103} height={103} alt="" />
            </div>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-7.png" width={244} height={43} alt="" />
            </div>
            <div className={styles.item}>
              <Image src="/images/home/In-the-news-8.png" width={170} height={70} alt="" />
            </div>
          </div> */}
        </div>
      </PC>
      <Mobile>
        <div className={styles.content}>
          <Swiper onInit={handleSwiperInit} enabled={isMobile} loop autoplay={{ disableOnInteraction: false }}>
            <SwiperSlide>
              <div className={styles.line}>
                {NEWS_LIST.map((item) => (
                  <Link key={item.key} href={item.linkUrl} passHref target="_blank" className={styles.item}>
                    <Image src={item.imgUrl} width={item.mWidth} height={item.mHeight} alt={item.mAlt} />
                  </Link>
                ))}
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className={styles.line}>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-1.png" width={53} height={19} alt="悟空中文在 Zino" />
                </div>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-2.png" width={61} height={40} alt="悟空中文在 险峰 k2vs" />
                </div>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-3.png" width={51} height={20} alt="悟空中文在 yahoo finance" />
                </div>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-4.png" width={68} height={9} alt="悟空中文在 yahoo finance" />
                </div>
              </div>
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <div className={styles.line}>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-5.png" width={44} height={37} alt="悟空中文在 美通社" />
                </div>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-6.png" width={37} height={37} alt="悟空中文" />
                </div>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-8.png" width={55} height={21} alt="悟空中文  edtech x" />
                </div>
                <div className={styles.item}>
                  <Image src="/images/home/In-the-news-7.png" width={68} height={11} alt="悟空中文 36Kr" />
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </Mobile>
    </section>
  );
};

export default InTheNews;
