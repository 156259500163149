import React, { useContext } from 'react';
import cls from 'classnames';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';

const ChineseCourse: React.FC = () => {
  const { t } = useTranslation('home');
  const { locale } = useContext(MyContext);
  const courseConfig = [
    {
      lecture: t('课程-中文-启蒙-体系'),
      color: 'red',
      features: t('课程-中文-启蒙-特色'),
      textbook: t('课程-中文-启蒙-教材'),
      level: t('课程-中文-启蒙-分级'),
    },
    {
      lecture: t('课程-中文-国际-体系'),
      color: 'blue',
      lectureImage: '/images/home/course-l.svg',
      features: t('课程-中文-国际-特色'),
      textbook: t('课程-中文-国际-教材'),
      level: t('课程-中文-国际-分级'),
    },
    {
      lecture: t('课程-中文-进阶-体系'),
      color: 'green',
      lectureImage: '/images/home/course-g.svg',
      features: t('课程-中文-进阶-特色'),
      textbook: t('课程-中文-进阶-教材'),
      level: t('课程-中文-进阶-分级'),
    },
    {
      lecture: t('课程-中文-基础-体系'),
      color: 'yellow',
      lectureImage: '/images/home/course-s.svg',
      features: t('课程-中文-基础-特色'),
      textbook: t('课程-中文-基础-教材'),
      level: t('课程-中文-基础-分级'),
    },
  ];

  return (
    <div
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.line}>
        <Image src="/images/home/chinese-line.svg" width={564} height={46} alt="" />
      </div>
      <table>
        <thead>
          <tr>
            <th>{t('课程-课程体系')}</th>
            <th>{t('课程-课程特色')}</th>
            <th>{t('课程-课程教材')}</th>
            <th>{t('课程-分级水平')}</th>
          </tr>
        </thead>
        <tbody>
          {courseConfig.map((item) => {
            return (
              <tr className={styles[item.color]} key={item.lecture}>
                <td width={188}>
                  <div className={styles.text}>
                    <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.lecture }} />
                    {/* {item.lectureImage && (
                      <div className={styles.courseLevel}>
                        <Image src={item.lectureImage} width={49} height={28} alt="" />
                      </div>
                    )} */}
                  </div>
                </td>
                <td width={365}>
                  <div
                    className={cls(styles.text, styles.features)}
                    dangerouslySetInnerHTML={{ __html: item.features }}
                  />
                </td>
                <td width={365} className={styles.textBooksTd}>
                  <div
                    className={cls(styles.text, styles.textbooks)}
                    dangerouslySetInnerHTML={{ __html: item.textbook }}
                  />
                </td>
                <td width={365} className={styles.levelTd}>
                  <div className={cls(styles.text, styles.level)} dangerouslySetInnerHTML={{ __html: item.level }} />
                  <div className={styles.arrow}>
                    <Image src="/images/home/chinese-arrow.png" width={88} height={76} alt="" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChineseCourse;
