import React, { useContext } from 'react';

import Image from 'next/legacy/image';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';

const MathCourse: React.FC = () => {
  const { t } = useTranslation('home');
  const { locale } = useContext(MyContext);

  return (
    <div
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.rocket}>
        <Image src="/images/home/math-rocket.png" width={716} height={242} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.line}>
            <p>{t('课程-课程体系')}</p>
            <p>{t('课程-数学-A2-体系')}</p>
            <p>{t('课程-数学-A5-体系')}</p>
            <p>{t('课程-数学-A8-体系')}</p>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.line}>
            <p>{t('课程-课程特色')}</p>
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A2-特色') }} />
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A5-特色') }} />
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A8-特色') }} />
          </div>
          <div className={styles.line}>
            <p>{t('课程-课程资料')}</p>
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A2-资料') }} />
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A5-资料') }} />
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A8-资料') }} />
          </div>

          <div className={styles.line}>
            <p>{t('课程-课程目标')}</p>
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A2-目标') }} />
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A5-目标') }} />
            <p dangerouslySetInnerHTML={{ __html: t('课程-数学-A8-目标') }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MathCourse;
