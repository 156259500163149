import React, { useContext } from 'react';
import { Fade, Zoom } from 'react-awesome-reveal';

import Image from 'next/legacy/image';

import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { traceEvent } from '@wk/wk-gatherer';
import Button from '@/components/Button';
import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';
import useIsMobile from '@/hooks/useIsMobile';
import useGTM from '@/hooks/useGtag';
import { generateNamePrefix } from '@/hooks/helpers';

const Projects: React.FC = () => {
  const { t } = useTranslation(['home', 'path']);
  const { locale } = useContext(MyContext);

  let mathTitle = t('产品-数学-标题');
  const isMobile = useIsMobile();
  if (isMobile) {
    mathTitle = mathTitle.replace(/\n/, '');
  }
  const sendDataToGTM = useGTM();
  const prefixName = generateNamePrefix(`HomePage_`);
  const handleGA = (gaName: string) => {
    sendDataToGTM(prefixName`${gaName}`);
  };

  const handleTraceEvent = (eventName: string, button_name: string) => {
    traceEvent({
      data: {
        _event: eventName,
        button_name,
      },
    });
  };
  return (
    <section
      className={cls(styles.box, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <Zoom triggerOnce className={styles.chineseCircle}>
        <div />
      </Zoom>
      <div className={styles.overflow}>
        <div className={styles.container}>
          <Fade direction="left" triggerOnce className={styles.bg} delay={300}>
            <Image
              src="/images/home/project-chinese.png"
              objectFit="cover"
              objectPosition="right bottom"
              layout="fill"
              alt=""
            />
          </Fade>
          <div className={styles.textContainer}>
            <div className={styles.text}>
              <Fade direction="left" triggerOnce cascade damping={0.1}>
                <div className={styles.title}>
                  <h2>{t('产品-中文-标题')}</h2>
                  <span className={styles.yearsOld}>{t('产品-中文-年龄')}</span>
                </div>
                <p className={styles.content}>{t('产品-中文-内容')}</p>
                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.button}
                    theme="red"
                    reverse
                    href={t('path:中文落地页')}
                    target="_blank"
                    onClick={() => {
                      handleGA('BookingChineseCourses');
                      handleTraceEvent('O_WKZWClick', t('产品-中文-按钮'));
                    }}
                  >
                    {t('产品-中文-按钮')}
                  </Button>
                </div>
              </Fade>
            </div>
            <Fade triggerOnce direction="up" delay={500} className={styles.preview}>
              <Image
                src="/images/home/project-chinese-preview.png"
                width={674}
                height={571}
                layout="responsive"
                alt={t('悟空中文课-alt')}
              />
            </Fade>
          </div>
          <div className={styles.line}>
            <Image src="/images/home/project-line.svg" priority layout="fill" alt="" />
          </div>
        </div>
        <div className={cls(styles.container, styles.math)}>
          <div className={styles.mathCookie}>
            <Image src="/images/home/project-math-cookie.png" width={59} height={72} alt="" />
          </div>
          <Fade triggerOnce className={styles.bg} direction="right">
            <Image
              src="/images/home/project-math.png"
              objectFit="cover"
              objectPosition="left bottom"
              layout="fill"
              alt=""
            />
          </Fade>
          <div className={styles.textContainer}>
            <div className={styles.text}>
              <Fade triggerOnce cascade damping={0.1} delay={300} direction="right">
                <div className={styles.title}>
                  <h2>{mathTitle}</h2>
                  <span className={styles.yearsOld}>{t('产品-数学-年龄')}</span>
                </div>
                <p className={styles.content}>{t('产品-数学-内容')}</p>
                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.button}
                    theme="blue"
                    reverse
                    href={t('path:数学落地页')}
                    target="_blank"
                    onClick={() => {
                      handleGA('BookingMathCourses');
                      handleTraceEvent('O_MathsCourseClick', t('产品-数学-按钮'));
                    }}
                  >
                    {t('产品-数学-按钮')}
                  </Button>
                </div>
              </Fade>
            </div>
            <Fade triggerOnce direction="up" delay={500} className={styles.preview}>
              <Image
                src="/images/home/project-math-preview.png"
                width={650}
                height={505}
                layout="responsive"
                alt={t('悟空数学课-alt')}
              />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
