import React from 'react';
import Image from 'next/legacy/image';
import cls from 'classnames';

// import padStart from 'lodash/padStart';
import styles from './style.module.scss';

export interface TeacherProps {
  picture: string;
  name: string;
  title?: string;
  school: string;
  experience: string;
  theme?: string;
  locale?: string;
  alt?: string;
}

const TeachersCard = ({ picture, name, title, school, experience, theme = '', locale, alt }: TeacherProps) => (
  <div
    className={cls(styles.box, styles[theme], {
      [styles.localeEn]: locale === 'en',
    })}
  >
    {/* 头像 */}
    <div className={styles.headPortrait}>
      <Image src={picture} width={332} height={332} layout="responsive" alt={alt} />
    </div>
    {/* 介绍 */}
    <div className={styles.info}>
      <p className={styles.infoTextName}>{name}</p>
      <p className={styles.infoText}>{school}</p>
      {experience === '&nbsp;' ? '' : <p className={styles.infoP} dangerouslySetInnerHTML={{ __html: experience }} />}
      {title && <p className={styles.infoP} dangerouslySetInnerHTML={{ __html: title }} />}
    </div>
  </div>
);
export default TeachersCard;
