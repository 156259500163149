import React, { useContext } from 'react';
import Image from 'next/legacy/image';
import { Parallax } from 'react-scroll-parallax';
import cls from 'classnames';
import { i18n, useTranslation } from 'next-i18next';
import { MyContext } from '@/config/context-manager';
import Form from '../../Form';

// import useGTM from '@/hooks/useGtag';
import styles from './style.module.scss';

const Index: React.FC = () => {
  const { t } = useTranslation(['home', 'path']);
  const { locale } = useContext(MyContext);
  // const sendDataToGTM = useGTM();

  // const handleGA = () => {
  //   sendDataToGTM('HomePage_TryItNow');
  // };

  return (
    <section
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.bg}>
        <Image
          src="/images/home/banner-bg.svg"
          layout="fill"
          objectFit="cover"
          priority
          objectPosition="top left"
          alt="悟空中文"
        />
        <p className={styles.bgTextEdu}>EDU</p>
        <p className={styles.bgTextCation}>CATION</p>
      </div>
      <div className={styles.titleContainer}>
        {/* 哈佛论坛 */}
        <div className={styles.harvard}>
          <div className={styles.harvardImg}>
            <Image
              width={164}
              height={76}
              layout="responsive"
              src="/images/home/banner-cooperation.png"
              alt="悟空中文"
            />
          </div>
          <p className={styles.harvardText}>{t('头图-哈佛中国教育论坛')}</p>
        </div>
        <div className={styles.titleGroup}>
          <h1>
            <p className={styles.title}>{t('头图-标语')}</p>
            <p className={styles.yearsOld}>{t('头图-年龄')}</p>
            <p className={`${styles.lessons} ${styles[`lessons-${i18n?.language}`]}`}>
              {t('头图-标语-chinese').trim()}
              <span>|</span>
              {t('头图-标语-math').trim()}
              <span>|</span>
              {t('头图-标语-english').trim()}
            </p>
          </h1>
        </div>
        <Parallax y={[-5, 5]} className={styles.pc}>
          <Parallax y={[-50, 50]} className={styles.ip}>
            <Image src={require('@public/images/home/banner-ip.png')} layout="responsive" alt="悟空中文的悟空" />
          </Parallax>
          {/* <Parallax y={[50, 240]} className={styles.ipHi}>
            <Image
              // eslint-disable-next-line import/no-dynamic-require
              src={require(`@public/images/home/home-hi-${locale === 'en' ? 'en' : 'zh'}.svg`)}
              layout="responsive"
              alt="悟空中文的悟空"
            />
          </Parallax> */}
          <Image src="/images/home/banner-pc.png" width={641} height={388} layout="responsive" alt={t('横折钩-alt')} />
        </Parallax>
      </div>
      <Parallax y={[-20, 20]} className={styles.pic} styleInner={{ height: '100%' }}>
        <div className={styles.picNew}>
          <Image
            src="/images/home/banner-main-new.png"
            width={645}
            height={610}
            layout="responsive"
            alt={t('横折钩-alt')}
          />
        </div>
      </Parallax>
      <div className={styles.formContainer}>
        <Form />
      </div>
    </section>
  );
};

export default Index;
