/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';
import { BlogPost } from '@/interface/home';

export interface IBlogProps {
  blogPosts: BlogPost[];
}

const Blog = ({ blogPosts }: IBlogProps) => {
  // console.log('blogPosts', blogPosts);
  const { locale } = useContext(MyContext);
  const { t } = useTranslation(['common', 'path', 'home']);

  return (
    <section
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{t('博客')}</h2>
      </div>
      <div className={styles.group}>
        <div className={styles.groupLeft}>
          {blogPosts?.slice(0, 1).map((item) => (
            <a href={item.post_link} key={item.post_id} target="_blank" className={styles.groupA}>
              <article className={styles.groupLeftItem}>
                <img
                  className={styles.leftItemImg}
                  src={item.thumbnail ? item.thumbnail : '/images/home/blog-post-default.jpg'}
                  alt="wukong blog"
                />
                <div className={styles.itemTop}>
                  <span className={styles.categories}>{item?.post_cat}</span>
                  <span className={styles.readTime}>{t('home:阅读时长', { 时间: item?.read_time || '2' })}</span>
                </div>
                <p className={styles.blogTitle}>{item?.post_title}</p>
                <div className={styles.blogExcerpt} dangerouslySetInnerHTML={{ __html: item?.post_excerpt }} />
              </article>
            </a>
          ))}
        </div>
        <div className={styles.groupRight}>
          {blogPosts?.slice(1, 5).map((item) => (
            <a href={item.post_link} key={item.post_id} target="_blank" className={styles.groupA}>
              <article className={styles.groupRightItem}>
                <div className={styles.itemTop}>
                  <span className={styles.categories}>{item?.post_cat}</span>
                  <span className={styles.readTime}>{t('home:阅读时长', { 时间: item?.read_time || '2' })}</span>
                </div>
                <p className={styles.blogTitle}>{item?.post_title}</p>
              </article>
            </a>
          ))}
        </div>
      </div>
      {/* 临时用a标签 link 新版本需要兼容 当前研发不是next13 */}
      <a href={t('path:博客')} target="_blank" className={styles.more}>
        {t('查看更多')}
      </a>
    </section>
  );
};

export default Blog;
