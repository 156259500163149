import React, { useContext, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import SwiperCore, { Pagination } from 'swiper/core';
import Image from 'next/legacy/image';
import { Fade } from 'react-awesome-reveal';
import SwiperClass from 'swiper/types/swiper-class';
import { MyContext } from '@/config/context-manager';

import Title from '@/components/Home/Title';
import TeachersCard, { TeacherProps } from '@/components/Home/TeachersCard';
import Button from '@/components/Button';
import PC from '@/components/Device/PC';
import Mobile from '@/components/Device/Mobile';
import { ISwiper, SwiperSlide } from '@/components/Swiper';
import useGTM from '@/hooks/useGtag';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import styles from './style.module.scss';

SwiperCore.use([Pagination]);

const Teachers: React.FC = () => {
  const { t } = useTranslation('home');
  const swiperRef = useRef<SwiperClass>();
  const { locale } = useContext(MyContext);
  const sendDataToGTM = useGTM();

  const handleGA = () => {
    sendDataToGTM('HomePage_WukongTeachers');
  };

  const teachersCardConfigs: TeacherProps[] = [
    {
      picture: '/images/home/teachers-people-6.png',
      name: t('师资力量-1-姓名'),
      school: t('师资力量-1-学校'),
      experience: t('师资力量-1-头衔'),
      title: t('师资力量-1-介绍'),
      theme: 'card-1',
      alt: t('茂英老师-alt'),
    },
    {
      picture: '/images/home/teachers-people-7.png',
      name: t('师资力量-2-姓名'),
      school: t('师资力量-2-学校'),
      experience: t('师资力量-2-头衔'),
      title: t('师资力量-2-介绍'),
      theme: 'card-2',
      alt: t('Nathan老师-alt'),
    },
    {
      picture: '/images/home/teachers-people-5.png',
      name: t('师资力量-3-姓名'),
      school: t('师资力量-3-学校'),
      experience: t('师资力量-3-头衔'),
      title: t('师资力量-3-介绍'),
      theme: 'card-3',
      alt: t('若梅老师-alt'),
    },
    {
      picture: '/images/home/teachers-people-3.png',
      name: t('师资力量-4-姓名'),
      school: t('师资力量-4-学校'),
      experience: t('师资力量-4-头衔'),
      title: t('师资力量-4-介绍'),
      theme: 'card-4',
      alt: t('丹丹老师-alt'),
    },
    {
      picture: '/images/home/teachers-people-8.png',
      name: t('师资力量-5-姓名'),
      school: t('师资力量-5-学校'),
      experience: t('师资力量-5-头衔'),
      title: t('师资力量-5-介绍'),
      theme: 'card-5',
      alt: t('Maryam老师-alt'),
    },
    {
      picture: '/images/home/teachers-people-4.png',
      name: t('师资力量-6-姓名'),
      school: t('师资力量-6-学校'),
      experience: t('师资力量-6-头衔'),
      title: t('师资力量-6-介绍'),
      theme: 'card-6',
      alt: t('之之老师-alt'),
    },
  ];

  const handleSwiperInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
  };

  return (
    <section
      className={cls(styles.containerBox, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <PC>
        <div className={styles.contentBg}>
          <Image src="/images/home/teachers-bg.png" layout="fill" objectFit="cover" alt="" />
        </div>
      </PC>
      <Mobile>
        <div className={styles.contentBg}>
          <Image src="/images/home/teachers-bg-mobile.png" layout="fill" objectFit="cover" alt="" />
        </div>
      </Mobile>
      <div className={styles.container}>
        <Fade triggerOnce cascade damping={0.2} direction="up">
          <Title className={cls(styles.titleOne)} theme="AllWhite">
            {t('师资力量-标题')}
          </Title>
          <p className={cls(styles.title, styles.titleTwo)}>{t('师资力量-副标题')}</p>
          <div
            className={cls(styles.title, styles.titleThree)}
            dangerouslySetInnerHTML={{ __html: t('师资力量-内容') }}
          />
          {/* {t('师资力量-内容')} */}
          <div className={styles.teachersCardBox}>
            <ISwiper
              onInit={handleSwiperInit}
              className={styles.swiper}
              spaceBetween={50}
              centeredSlides
              centeredSlidesBounds
              loop
              slideToClickedSlide
              pagination={{
                clickable: true,
              }}
              slidesPerView="auto"
              breakpoints={{
                '720': {
                  slidesPerView: 3,
                },
              }}
            >
              {teachersCardConfigs.map((item) => {
                return (
                  <SwiperSlide key={item.theme}>
                    <TeachersCard
                      picture={item.picture}
                      name={item.name}
                      school={item.school}
                      experience={item.experience}
                      title={item.title}
                      theme={item.theme}
                      locale={locale}
                      alt={item.alt}
                    />
                  </SwiperSlide>
                );
              })}
            </ISwiper>
          </div>
        </Fade>
        <div className={styles.buttonBox}>
          <Button theme="red" href="/teachers" reverse onClick={handleGA}>
            {t('悟空师资-按钮')}
          </Button>
        </div>
        <div className={styles.schoolBox}>
          <span className={styles.school}>{t('悟空师资-日本北海道大学')}</span>
          <span className={styles.school}>{t('悟空师资-北大')}</span>
          <span className={styles.school}>{t('悟空师资-哈佛')}</span>
        </div>
      </div>
    </section>
  );
};

export default Teachers;
