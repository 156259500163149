import React, { useContext } from 'react';
import Image from 'next/legacy/image';
import cls from 'classnames';
// import padStart from 'lodash/padStart';
import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';

export interface AdvantageCardProps {
  active: boolean;
  picture: string;
  title: string;
  items: string[];
  theme: 'red' | 'yellow' | 'blue';
  // index: number;
  className?: string;
  alt?: string;
}

const AdvantageCard = ({ active, picture, title, items, theme, className, alt }: AdvantageCardProps) => {
  const { locale } = useContext(MyContext);
  return (
    <div
      className={cls(styles.box, className, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <dl className={cls(styles.container, styles[`theme-${theme}`], { [styles.active]: active })}>
        <dt className={styles.picture}>
          <Image src={picture} width={416} height={345} layout="responsive" alt={alt} />
        </dt>
        <dd className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <ul className={styles.list}>
            {items.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>
          {/* <div className={styles.index}>{padStart(String(index || ''), 2, '0')}</div> */}
        </dd>
      </dl>
    </div>
  );
};
export default AdvantageCard;
