import React, { useState, useContext } from 'react';
import Image from 'next/legacy/image';
import cls from 'classnames';
import { Fade } from 'react-awesome-reveal';

import { useTranslation } from 'next-i18next';
import SwiperClass from 'swiper/types/swiper-class';
import SwiperCore, { Pagination } from 'swiper/core';
import { traceEvent } from '@wk/wk-gatherer';
import AdvantageCard, { AdvantageCardProps } from '@/components/Home/AdvantageCard';
import { MyContext } from '@/config/context-manager';

import { ISwiper, SwiperSlide } from '@/components/Swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import styles from './style.module.scss';

SwiperCore.use([Pagination]);

const SWIPER_INITIAL_INDEX = 1;

const Advantage: React.FC = () => {
  const { t } = useTranslation('home');
  const { locale } = useContext(MyContext);
  const [activeKey, setActiveKey] = useState(SWIPER_INITIAL_INDEX);

  const advantageCardConfigs: Omit<AdvantageCardProps, 'active' | 'index'>[] = [
    {
      picture: '/images/home/advantage-people.jpg',
      title: t('特点-学员为本-标题'),
      items: t('特点-学员为本-内容').split(';'),
      theme: 'yellow',
      alt: t('教育理念-alt'),
    },
    {
      picture: '/images/home/advantage-science.jpg',
      title: t('特点-科学化教学体系-标题'),
      items: t('特点-科学化教学体系-内容').split(';'),
      theme: 'red',
      alt: t('教学方法-alt'),
    },
    {
      picture: '/images/home/advantage-custom.jpg',
      title: t('特点-定制化-标题'),
      items: t('特点-定制化-内容').split(';'),
      theme: 'blue',
      alt: t('授课形式-alt'),
    },
  ];

  const handleSwiperChange = (swiper: SwiperClass) => {
    setActiveKey(swiper.realIndex);
    traceEvent({
      data: {
        _event: 'O_PictureClick',
        picture_name: advantageCardConfigs[swiper.realIndex].title,
      },
    });
  };

  return (
    <section
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <Fade triggerOnce cascade damping={0.1} direction="left">
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: t('特点-标题') }} />
            <p className={styles.desc}>{t('特点-副标题')}</p>
            <div className={styles.imgContainer}>
              <Image src="/images/home/advantage-line.png" width={651} height={502} layout="responsive" alt="" />
            </div>
          </Fade>
        </div>
        <div className={styles.cards}>
          <Fade triggerOnce direction="up">
            <ISwiper
              className={styles.swiper}
              centeredSlides
              // slidesPerView={isMobile ? 1 : 3}
              initialSlide={SWIPER_INITIAL_INDEX}
              loop
              slideToClickedSlide
              onSlideChange={handleSwiperChange}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                '720': {
                  slidesPerView: 3,
                },
              }}
              slidesPerView="auto"
            >
              {advantageCardConfigs.map((item, index) => {
                return (
                  <SwiperSlide key={item.title}>
                    <AdvantageCard
                      className={styles.card}
                      // index={index === 0 ? advantageCardConfigs.length : index}
                      active={activeKey === index}
                      picture={item.picture}
                      title={item.title}
                      items={item.items}
                      theme={item.theme}
                      alt={item.alt}
                    />
                  </SwiperSlide>
                );
              })}
            </ISwiper>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Advantage;
