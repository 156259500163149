import React, { useState, useContext } from 'react';
import { Fade } from 'react-awesome-reveal';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { traceEvent } from '@wk/wk-gatherer';
import PC from '@/components/Device/PC';
import Button from '@/components/Button';
import ChineseCourse from '@/components/Home/ChineseCourse';
import MathCourse from '@/components/Home/MathCourse';
import Title from '@/components/Home/Title';

import { MyContext } from '@/config/context-manager';
import styles from './style.module.scss';

const Courses: React.FC = () => {
  const { t } = useTranslation('home');
  const [currentKey, setCurrentKey] = useState('chinese');
  const { locale } = useContext(MyContext);
  const handleItemClick = (key: string) => setCurrentKey(key);

  const handleTraceEvent = (tab_name: string) => {
    traceEvent({
      data: {
        _event: 'O_KCTXTabClick',
        tab_name,
      },
    });
  };

  return (
    <PC>
      <section className={cls(styles.container, { [styles.localeEn]: locale === 'en' })}>
        <Fade cascade direction="up" triggerOnce damping={0.1}>
          <Title>{t('课程-标题')}</Title>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              theme={currentKey === 'chinese' ? 'red' : 'white'}
              onClick={() => {
                handleItemClick('chinese');
                handleTraceEvent(t('课程-中文-按钮'));
              }}
              size="l"
            >
              {t('课程-中文-按钮')}
            </Button>
            <Button
              className={styles.button}
              theme={currentKey === 'math' ? 'blue' : 'white'}
              onClick={() => {
                handleItemClick('math');
                handleTraceEvent(t('课程-数学-按钮'));
              }}
              size="l"
            >
              {t('课程-数学-按钮')}
            </Button>
          </div>
          {currentKey === 'chinese' ? <ChineseCourse /> : <MathCourse />}
        </Fade>
      </section>
    </PC>
  );
};

export default Courses;
