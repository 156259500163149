import React, { useContext } from 'react';
import cls from 'classnames';
import dynamic from 'next/dynamic';

import Image from 'next/legacy/image';
// import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import { useTranslation } from 'next-i18next';
// import { Fade } from 'react-awesome-reveal';
import { MyContext } from '@/config/context-manager';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';

import Title from '@/components/Home/Title';
// import EvaluationCard from '@/components/Home/EvaluationCard';

import styles from './style.module.scss';
// import Trustpilot from '../Trustpilot';

const Trustpilot = dynamic(import('../Trustpilot'), { ssr: false });

SwiperCore.use([Navigation]);

const Evaluation: React.FC = () => {
  const { t } = useTranslation('home');
  const { locale } = useContext(MyContext);

  // const evaluations = [
  //   {
  //     picture: '/images/home/evaluation-kate.jpg',
  //     name: 'Kate L',
  //     info: t('评价-kate-介绍'),
  //     desc: t('评价-kate-推荐语'),
  //     star: 5,
  //   },
  //   {
  //     picture: '/images/home/evaluation-jerry.jpg',
  //     name: 'Jerry W',
  //     info: t('评价-jerry-介绍'),
  //     desc: t('评价-jerry-推荐语'),
  //     theme: 'blue' as const,
  //     star: 4,
  //   },
  //   {
  //     picture: '/images/home/evaluation-sarah.jpg',
  //     name: 'Sarah J',
  //     info: t('评价-sarah-介绍'),
  //     desc: t('评价-sarah-推荐语'),
  //     star: 5,
  //   },
  // ];

  return (
    <section
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.titleContainer}>
        {/* <Fade triggerOnce cascade damping={0.2} direction="up">
          <Title>{t('学员评价-标题')}</Title>
          <p className={styles.subTitle} dangerouslySetInnerHTML={{ __html: t('学员评价-副标题') }} />
        </Fade> */}
        <div>
          <Title>{t('学员评价-标题')}</Title>
          <p className={styles.subTitle} dangerouslySetInnerHTML={{ __html: t('学员评价-副标题') }} />
        </div>
      </div>
      {/* <Fade direction="right" triggerOnce className={styles.bg}>
        <Image src="/images/home/evaluation-bg.png" width={470} height={738} alt="" />
      </Fade> */}
      <div className={styles.bg}>
        <Image src="/images/home/evaluation-bg.png" width={470} height={738} alt="" />
      </div>
      <div className={styles.trustpilot}>
        <Trustpilot />
      </div>
      {/* <Fade direction="up" triggerOnce className={styles.cardContainer}>
        <Swiper navigation loop>
          {evaluations.map((item) => {
            return (
              <SwiperSlide className={styles.cardItem} key={item.name}>
                <EvaluationCard
                  picture={item.picture}
                  theme={item.theme}
                  desc={item.desc}
                  star={item.star}
                  info={item.info}
                  name={item.name}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Fade> */}
    </section>
  );
};

export default Evaluation;
