import React, { useContext } from 'react';
import Image from 'next/legacy/image';
import { Flip, Fade } from 'react-awesome-reveal';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { traceEvent } from '@wk/wk-gatherer';
import Button from '@/components/Button';

import { MyContext } from '@/config/context-manager';
import Mobile from '@/components/Device/Mobile';
import PC from '@/components/Device/PC';
import useGTM from '@/hooks/useGtag';

import styles from './style.module.scss';

const Choose: React.FC = () => {
  const { t } = useTranslation(['home', 'path']);
  const { locale } = useContext(MyContext);
  const sendDataToGTM = useGTM();

  const handleGA = () => {
    sendDataToGTM('HomePage_TryItNow');
    traceEvent({
      data: {
        _event: 'O_MSSTClick',
        button_name: t('选择悟空-按钮'),
      },
    });
  };
  return (
    <section
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <div className={styles.bgContainer}>
        <div className={styles.bg}>
          <Mobile>
            <div>
              <Image src="/images/home/choose-mobile.png" width={374} height={251} layout="responsive" alt="" />
            </div>
          </Mobile>
          <PC>
            <div>
              <Image src="/images/home/choose.png" width={1920} height={440} layout="responsive" alt="" />
            </div>
          </PC>
          <div className={styles.ipFamily}>
            <Image
              src={require('@public/images/home/ip-family.png')}
              width={619}
              height={416}
              layout="responsive"
              alt="悟空中文全家福"
            />
          </div>
        </div>
      </div>
      <div className={styles.titleContainer}>
        <Flip triggerOnce direction="horizontal" delay={200} style={{ display: 'inline-block' }}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: t('选择悟空-标题') }} />
        </Flip>
        <div className={styles.buttonContainer}>
          <Fade triggerOnce direction="up">
            <Button className={styles.button} href="/appointment" theme="red" reverse onClick={handleGA}>
              {t('选择悟空-按钮')}
            </Button>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Choose;
