import React, { useEffect, useRef, useContext } from 'react';
import Image from 'next/legacy/image';
import { Fade } from 'react-awesome-reveal';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { MyContext } from '@/config/context-manager';

import Title from '@/components/Home/Title';
import useIsMobile from '@/hooks/useIsMobile';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import styles from './style.module.scss';

interface IFeatureProps {
  picture: string;
  title: string;
  desc: string;
  total?: number;
  current?: number;
  alt?: string;
  onTabItemClick?(index: number): void;
}

const Feature: React.FC<IFeatureProps> = ({ picture, title, desc, total = 0, current, alt, onTabItemClick }) => {
  return (
    <div className={cls(styles.cardContainer)}>
      <ul className={styles.nav}>
        {[...new Array(total)].map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => onTabItemClick?.(index)}
              className={cls(styles.navItem, {
                [styles.navActive]: index === current,
              })}
            >
              {index + 1}
            </li>
          );
        })}
      </ul>
      <dl className={styles.card}>
        <dt className={styles.imageContainer}>
          <Image src={picture} layout="fill" objectFit="cover" alt={alt} />
        </dt>
        <dd className={styles.cardContent}>
          <h3 className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: title }} />
          <p className={styles.desc} dangerouslySetInnerHTML={{ __html: desc }} />
        </dd>
      </dl>
    </div>
  );
};

const Features: React.FC = () => {
  const isMobile = useIsMobile();
  const swiperRef = useRef<SwiperClass>();
  const { t } = useTranslation('home');
  const { locale } = useContext(MyContext);
  const featureConfig: IFeatureProps[] = [
    {
      title: t('特色-沉浸式-标题'),
      picture: '/images/home/feature-1.jpg',
      desc: t('特色-沉浸式-内容'),
      alt: t('悟空特色-1-alt'),
    },
    {
      title: t('特色-多维度-标题'),
      picture: '/images/home/feature-2.jpg',
      desc: t('特色-多维度-内容'),
      alt: t('悟空特色-2-alt'),
    },
    {
      title: t('特色-扩展式-标题'),
      picture: '/images/home/feature-3.jpg',
      desc: t('特色-扩展式-内容'),
      alt: t('悟空特色-3-alt'),
    },
    {
      title: t('特色-个性化-标题'),
      picture: '/images/home/feature-4.jpg',
      desc: t('特色-个性化-内容'),
      alt: t('悟空特色-4-alt'),
    },
    {
      title: t('特色-分级-标题'),
      picture: '/images/home/feature-5.jpg',
      desc: t('特色-分级-内容'),
      alt: t('悟空特色-5-alt'),
    },
    {
      title: t('特色-云端-标题'),
      picture: '/images/home/feature-6.jpg',
      desc: t('特色-云端-内容'),
      alt: t('悟空特色-6-alt'),
    },
  ];

  const handleSwiperInit = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
  };

  const handleTabItemClick = (index: number) => {
    swiperRef.current?.slideTo(index);
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.enabled = isMobile;
      if (isMobile) {
        swiperRef.current?.enable();
      } else {
        swiperRef.current?.disable();
      }
      swiperRef.current.update();
    }
  }, [swiperRef.current, isMobile]);

  return (
    <section
      className={cls(styles.container, {
        [styles.localeEn]: locale === 'en',
      })}
    >
      <Fade cascade direction="up" triggerOnce damping={0.1}>
        <Title className={styles.title}>{t('特色-标题')}</Title>
        <Swiper
          slidesPerView={1}
          slidesPerColumn={1}
          onInit={handleSwiperInit}
          speed={0}
          breakpoints={{
            '720': {
              slidesPerView: 3,
              slidesPerColumn: 2,
            },
          }}
        >
          {featureConfig.map((item, index) => (
            <SwiperSlide key={item.title} className="swiper-no-swiping">
              <div className={styles.cardBox}>
                <Feature
                  key={item.title}
                  desc={item.desc}
                  title={item.title}
                  picture={item.picture}
                  total={featureConfig.length}
                  current={index}
                  onTabItemClick={handleTabItemClick}
                  alt={item.alt}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Fade>
    </section>
  );
};

export default Features;
