import React from 'react';
import cls from 'classnames';

import styles from './style.module.scss';

type ThemeType = 'AllWhite' | 'default';
interface TitleProps {
  className?: string;
  theme?: ThemeType;
}

const Title: React.FC<TitleProps> = ({ className, children, theme = 'default' }) => {
  const classes = cls(styles.container, className, styles[`title${theme}`]);
  return (
    <div className={classes}>
      <h2 className={styles.content}>{children}</h2>
    </div>
  );
};

export default Title;
